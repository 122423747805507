export default [
    {
        path: "/klimatologi",
        name: "klimatologi",
        component: () => import("@/views/bmkgsatu/inputdata/Klimatologi.vue"),
    },
    {
        path: "/meteorologi/:tab?",
        name: "meteorologi",
        component: () => import("@/views/bmkgsatu/inputdata/Meteorologi.vue"),
    },
    {
        path: "/geofisika",
        name: "geofisika",
        component: () => import("@/views/bmkgsatu/inputdata/Geofisika.vue"),
    },
    {
        path: "/gts_messages",
        name: "gts_messages",
        component: () => import("@/views/bmkgsatu/inputdata/GTSMain.vue"),
    },
    {
        path: "/derivedata",
        name: "derive",
        component: () => import("@/views/bmkgsatu/outputdata/derivedata/DeriveMain.vue"),
    },
    {
        path: "/exportdata",
        name: "export",
        component: () => import("@/views/bmkgsatu/outputdata/exportdata/Exportdata.vue"),
    },
    {
        path: "/pelayanandata",
        name: "pelayanan",
        component: () => import("@/views/bmkgsatu/outputdata/pelayanandata/Pelayanandata.vue"),
    },
    {
        path: "/reports",
        name: "reports",
        // component: () => import("@/views/pages/miscellaneous/UnderMaintenance.vue"),
        component: () => import("@/views/bmkgsatu/outputdata/laporan/Laporan.vue"),
    },
    {
        path: "/integrasi",
        name: "integrasi",
        component: () => import("@/views/pages/miscellaneous/UnderMaintenance.vue"),
        // component: () => import("@/views/bmkgsatu/outputdata/integrasi/Integrasi.vue"),
    },
    {
        path: "/extractgts",
        name: "extract-gts",
        component: () => import("@/views/bmkgsatu/outputdata/extractgts/ExtractGts.vue"),
    },
    {
        path: "/raw_visualisasi",
        name: "raw_visualisasi",
        component: () => import("@/views/bmkgsatu/outputdata/visualisasi/RawVisualisasi.vue"),
    },
    {
        path: "/visualisasi",
        name: "visualisasi",
        component: () => import("@/views/bmkgsatu/outputdata/visualisasi/Visualisasidataparameter.vue"),
    },
    {
        path: "/visualisasidataraw",
        name: "visualisasidataraw",
        component: () => import("@/views/bmkgsatu/outputdata/visualisasi/Visualisasidataraw.vue"),
    },
    {
        path: "/visualisasiqualityassesment",
        name: "visualisasi_qa",
        component: () => import("@/views/bmkgsatu/outputdata/visualisasi/VisualisasiQualityAssesment.vue"),
    },
    {
        path: "/availability",
        name: "availability",
        component: () => import("@/views/bmkgsatu/outputdata/availability/Availability.vue"),
    },
    {
        path: "/monitoring",
        name: "monitoring",
        // component: () => import('@/views/bmkgsatu/monitoring/Monitoring.vue'),
    },
    {
        path: "/monitoring/klimatologi",
        name: "monitoring-klimatologi",
        component: () => import("@/views/bmkgsatu/monitoring/klimatologi/MKlimatologi.vue"),
    },
    {
        path: "/monitoring/meteorologi",
        name: "monitoring-meteorologi",
        component: () => import("@/views/bmkgsatu/monitoring/meteorologi/MMeteorologi.vue"),
    },
    {
        path: "/monitoring/geofisika",
        name: "monitoring-geofisika",
        component: () => import("@/views/bmkgsatu/monitoring/geofisika/MGeofisika.vue"),
    },
    {
        path: "/monitoring/data-integrasi",
        name: "monitoring-data-integrasi",
        component: () => import("@/views/bmkgsatu/monitoring/dataintegrasi/MDataIntegrasi.vue"),
    },
    {
        path: "/monitoring/gts",
        name: "monitoring-gts",
        component: () => import("@/views/bmkgsatu/monitoring/gts/MGts.vue"),
    },
    {
        path: "/qualitycontrol",
        name: "quality-control",
        component: () => import("@/views/bmkgsatu/qualitycontrol/Qc.vue"),
    },
    {
        path: "/qualitycontrol/rulesets",
        name: "qc-ruleset",
        component: () => import("@/views/bmkgsatu/qualitycontrol/rulesets/Rulesets.vue"),
    },
    {
        path: "/qualitycontrol/qcmonitoring",
        name: "qc-monitoring",
        component: () => import("@/views/bmkgsatu/qualitycontrol/qc_monitoring_ruleset/MonitoringRuleset.vue"),
    },
    {
        path: "/qualitycontrol/datasuspect",
        name: "qc-data-suspect",
        component: () => import("@/views/bmkgsatu/qualitycontrol/qcmonitor/QcMonitor.vue"),
    },
    {
        path: "/qualitycontrol/qcconfiguration",
        name: "qc-configuration",
        component: () => import("@/views/bmkgsatu/qualitycontrol/qcconfiguration/QcConfiguration.vue"),
    },
    {
        path: "/qualitycontrol/qcconfiguration/station",
        name: "qc-station",
        component: () => import("@/views/bmkgsatu/qualitycontrol/qcconfiguration/QcStation.vue"),
    },
    //metadata
    {
        path: "/metadatastasiun/synchronization",
        name: "synchronization",
        component: () => import("@/views/bmkgsatu/metadatastasiun/synchronization/Synchronization.vue"),
    },
    {
        path: "/metadatastasiun/metadata",
        name: "metadata",
        component: () => import("@/views/bmkgsatu/metadatastasiun/metadata/Metadata.vue"),
    },
    {
        path: "/metadata/stationprofiles",
        name: "metadata-stationprofiles",
        component: () => import("@/views/bmkgsatu/metadatastasiun/metadata/componenmetadata/Stationprofile.vue"),
    },

    //admin or manage user
    {
        path: "/admin",
        name: "admin",
        component: () => import("@/views/bmkgsatu/admin/Admin.vue"),
    },
    {
        path: "/admin/manageusers",
        name: "manage-users",
        component: () => import("@/views/bmkgsatu/admin/manageusers/ManageUser.vue"),
    },

    // {
    //   path: "/admin/manageusers/edit/:userId",
    //   name: "edit-user",
    //   component: () => import("@/views/bmkgsatu/admin/manageusers/EditUser.vue"),
    // },
    {
        path: "/admin/manageusers/detail/:userId",
        name: "detail",
        component: () => import("@/views/bmkgsatu/admin/manageusers/UserDetail.vue"),
    },
    {
        path: "/admin/manageroles",
        name: "manage-roles",
        component: () => import("@/views/bmkgsatu/admin/manageroles/ManageRoles.vue"),
    },
    {
        path: "/admin/manageassign",
        name: "manage-assign",
        component: () => import("@/views/bmkgsatu/admin/manageassign/ManageAssign.vue"),
    },

    //profile
    {
        path: "/profile",
        name: "profile-user",
        component: () => import("@/views/bmkgsatu/admin/manageusers/ProfileUser.vue"),
    },

    //log
    {
        path: "/log/aktifitaspengguna",
        name: "aktifitas-pengguna",
        component: () => import("@/views/bmkgsatu/log/aktifitaspengguna/AktifitasPengguna.vue"),
    },
    {
        path: "/log/prosesingest",
        name: "proses-ingest",
        component: () => import("@/views/bmkgsatu/log/prosesingest/ProsesIngest.vue"),
    },
    //setting
    {
        path: "/setting/region",
        name: "region",
        component: () => import("@/views/bmkgsatu/setting/Region/Region.vue"),
    },
    {
        path: "/setting/provinsi",
        name: "provinsi",
        component: () => import("@/views/bmkgsatu/setting/Provinsi/Provinsi.vue"),
    },
    {
        path: "/setting/kelurahan",
        name: "kelurahan",
        component: () => import("@/views/bmkgsatu/setting/Kelurahan/Kelurahan.vue"),
    },
    {
        path: "/setting/kabupaten",
        name: "kabupaten",
        component: () => import("@/views/bmkgsatu/setting/Kabupaten/Kabupaten.vue"),
    },
    {
        path: "/setting/elements",
        name: "manage-element",
        component: () => import("@/views/bmkgsatu/setting/Parameter/Parameter.vue"),
    },
    {
        path: "/setting/pejabat",
        name: "pejabat",
        component: () => import("@/views/bmkgsatu/setting/Pejabat/Pejabat.vue"),
    },
    {
        path: "/setting/observer",
        name: "observer",
        component: () => import("@/views/bmkgsatu/setting/Observer/Observer.vue"),
    },
    {
        path: "/setting/manageusage",
        name: "manage-usage",
        component: () => import("@/views/bmkgsatu/setting/Groupusage/Groupusage.vue"),
    },
    {
        path: "/setting/managetooltips",
        name: "manage-tooltips",
        component: () => import("@/views/bmkgsatu/setting/ManageTooltips/Managetooltips.vue"),
    },
    {
        path: "/setting/manageselect",
        name: "manage-select",
        component: () => import("@/views/bmkgsatu/setting/ManageSelectbox/Manageselect.vue"),
    },

    //laporan
    {
        path: "/report/sample",
        name: "report-sample",
        component: () => import("@/views/bmkgsatu/outputdata/laporan/sample.vue"),
    },

    //notifications
    {
        path: "/notifications",
        name: "notifications",
        component: () => import("@/views/bmkgsatu/notifications/index.vue"),
    },

    //menu ingest

    {
        path: "/ingest/datahistoris",
        name: "data-historis",
        component: () => import("@/views/pages/miscellaneous/UnderMaintenance.vue"),
        // component: () => import("@/views/bmkgsatu/ingest/datahistoris/IngestMain.vue"),
    },
    {
        path: "/ingest/dataraw",
        name: "data-raw",
        component: () => import("@/views/bmkgsatu/ingest/dataraw/DataRawIngest.vue"),
    },
    {
        path: "/ingest/monitoring",
        name: "monitoring-ingest",
        component: () => import("@/views/bmkgsatu/ingest/monitoring/MonitoringIngest.vue"),
    },
    {
        path: "/ingest/datalabku",
        name: "data-lab-ku",
        component: () => import("@/views/pages/miscellaneous/UnderMaintenance.vue"),
        // component: () => import("@/views/bmkgsatu/ingest/datalabku/IngestMain.vue"),
    },
];
